<template>
  <section class="home--about-container mb-4-5">
    <div class="container">
      <div class="title--text mb-4-5">
        {{ $t('home.media.title') }}
      </div>
      <div class="content content--youtube mb-4-5">
        <client-only>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/97bVpuh-YBQ?si=FFVREISEIJ_N5raV"
            :title="$t('home.media.youtube')"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            loading="lazy"
          ></iframe>
        </client-only>
      </div>
      <div class="content--coverage mb-4-5">
        <client-only>
          <slick-coverage />
        </client-only>
      </div>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from 'vue';
const SlickCoverage = defineAsyncComponent(() =>
  import('@/components/home/partials/slick-coverage.vue'),
);
export default {
  components: {
    SlickCoverage,
  },
  computed: {},
  methods: {},
};
</script>
